$theme-colors: (
  "primary": #102054,
  "dark": #000000,
);

$primary: #0b1d4f;
$blue-light: #1397d2;
$violet: #662172;
$green: #a3bc2a;
$dark-green: #2fa77b;

$enable-negative-margins: true;

@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

body {
  //padding-top: 80px;
  background-color: #eee;
  font-family: "Manrope", Arial, Helvetica, sans-serif;
}

p {
  font-size: 1.25em;
  font-family: "Manrope", Arial, Helvetica, sans-serif;
}
.break {
  flex-basis: 100%;
  height: 0;
}

.footer {
  margin-top: 2px;
}

.nav-item {
  display: block;
  position: relative;
}

.nav-item::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.nav-item:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-item:hover {
  a {
    color: #fff !important;
  }
}

.nav-item::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: #0068bc;
  z-index: -1;
  transition: transform 0.3s ease;
}

.navbar {
  border-bottom: 1px solid #eee;

  /* ul {
    li {
    }
  } */
}

.navbar-collapse {
  &.show,
  &.collapsing {
    margin-top: 34px;
    background: #fff;
  }
}

@media (max-width: 767.98px) {
  #main-naviguation .container-md {
    padding: 20px;
  }
  .navbar-brand {
    padding: 0 20px;
  }
}

header {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  .header-title {
    font-size: 3rem;
    font-family: "Manrope", sans-serif;
    u {
      position: relative;
      text-decoration: none;
      z-index: 1;
      display: inline-block;
      &:after {
        background: $blue-light;
        display: block;
        content: "";
        height: max(0.25em, 0.3em);
        left: 0.05em;
        right: 0.05em;
        bottom: 0.05em;
        position: absolute;
        z-index: -1;
        pointer-events: none;
      }
    }
  }

  .header-description {
    font-size: 1.5rem;
    font-family: "Manrope", Arial, Helvetica, sans-serif;
    max-width: 100vw;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  .overlay-blue {
    @extend .overlay;
    background-color: $blue-light;
  }

  .overlay-green {
    @extend .overlay;
    background-color: $green;
    opacity: 0.5;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

@media (pointer: coarse) and (hover: none) {
  /*  header {
    background: url("https://source.unsplash.com/XT5OInaElMw/1600x900") black
      no-repeat center center scroll;
  } */
  /* 
  header video {
    display: none;
  } */
}

section {
  //margin-bottom: $spacer * 5;
  &.bg-light {
    background-color: #eee;
  }
}

#main-navigation {
  position: absolute;
  height: 100px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  &.shrinked-navbar {
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
  }
}

#customers-list {
  margin-top: -100px;
  .card-icon {
    padding: 1rem 1rem 0 1rem;
    color: $blue-light;
  }
  .card {
    z-index: 2;
    border-top: 10px $blue-light solid;
  }
  .card-title {
    font-size: 1.5625rem;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
  }

  .card-footer {
    background: #fff;
    border-top: none;
  }
}

#equipe-list {
  margin-top: -100px;
  .card-icon {
    padding: 1rem 1rem 0 1rem;
    color: $blue-light;
  }
  .card {
    z-index: 2;
  }
  .card-image-label-btn-blue {
    background-color: $blue-light;
  }

  .card-image-label-btn-green {
    background-color: $green;
  }

  .card-title {
    font-size: 1.5625rem;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
  }

  .card-footer {
    background: #fff;
    border-top: none;
  }
}

.indicator {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  padding-left: $spacer * 3;
  .indicator-key {
    font-size: 60px;
    font-weight: 800;
    color: $blue-light;
    font-family: "Manrope", sans-serif;

    .material-icons {
      font-size: 60px;
      margin-bottom: 10px;
      display: block;
      text-align: center;
      margin-left: -3rem;
    }
  }
  .indicator-title {
    font-size: 1.5625rem;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
  }
}

.header-description {
  -webkit-animation: header-description 0.45s
    cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: header-description 0.45s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.text-image {
  .title {
    font-size: 2.4rem;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
  }

  @include media-breakpoint-up(xl) {
    .content {
      margin-left: 100px;
    }
    .img-out-right {
      margin-left: 100px;
    }
    .image {
      width: 100%;
      margin-left: 33%;
    }
  }

  ul {
    list-style: none; /* Remove default bullets */

    li {
      list-style: none;
    }
  }

  ul li::before {
    content: "\25A0";
    color: $blue-light;
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    //background-color: red; /* Change the color */
    //font-weight: bold; /* If you want it to be bold */
    display: inline-block;
    font-size: 0.5em;
    /* Needed to add space between the bullet and the text */
    /* Also needed for space (tweak if needed) */
    padding: 0 9px;
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
}

@-webkit-keyframes header-description {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(-400px);
    transform: translateZ(-400px);
  }
}

@keyframes header-description {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(-400px);
    transform: translateZ(-400px);
  }
}

.title {
  u {
    position: relative;
    text-decoration: none;
    z-index: 1;
    display: inline-block;
    &:after {
      background: $green;
      display: block;
      content: "";
      height: max(0.25em, 0.3em);
      left: 0.05em;
      right: 0.05em;
      bottom: 0.05em;
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }
  }
}

.card-footer {
  padding-bottom: $spacer * 3;
  a {
    text-decoration: none;
    font-size: 0.875em;
    font-family: "Manrope", Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  .btn-txt {
    color: #000;
    text-decoration: none !important;
  }
}

.splide__arrow {
  border-radius: 0% !important;
}

.underline-link {
  text-decoration: none;
  &:after {
    display: block;
    content: "";
    width: 90%;
    float: right;
    height: 2px;
    left: 1rem;
    right: 0;
    margin-top: 1px;
    opacity: 1;
    top: auto;
    bottom: -0.55em;
    z-index: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: 300ms ease transform;
    pointer-events: none;
    background: $green;
  }
}

.underline-title {
  text-decoration: none;
  &:after {
    display: block;
    content: "";
    clear: both;
    width: 3em;
    display: block;
    background: $blue-light;
    height: 2px;
    margin: 10px 0 0 -15px;
  }
}

.section-title {
  font-size: 2.75rem;
  font-weight: 800;
  font-family: "Manrope", Arial, Helvetica, sans-serif;

  u {
    position: relative;
    text-decoration: none;
    z-index: 1;
    display: inline-block;
    &:after {
      background: $green;
      display: block;
      content: "";
      height: max(0.25em, 0.3em);
      left: 0.05em;
      right: 0.05em;
      bottom: 0.05em;
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }
  }
}

.underline-title {
  text-decoration: none;
  &:after {
    display: block;
    content: "";
    clear: both;
    width: 3em;
    display: block;
    background: $blue-light;
    height: 2px;
    margin: 10px 0 0 -15px;
  }
}

#actu-list {
  article {
    margin-bottom: 50px;
    padding-bottom: 25px;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    .btn {
      min-height: 4em;
      padding: 1.063em 1.5em;
      background-color: #eee;
    }
  }
  h2 {
    font-family: "Manrope", sans-serif;
    font-size: 1.875rem;
    font-weight: 800;
  }
  aside {
    img {
      padding-right: 10px;
      max-height: 100px;
    }
    h4 {
      font-family: "Manrope", sans-serif;
      font-weight: 800;
      font-size: 1.25em;
      margin-bottom: 1.25em;
      padding-bottom: 0;
      display: inline-block;
      width: 100%;
    }

    .list-group-item {
      padding: 25px;
      border: none;
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
}

article {
  h4 {
    font-family: "Manrope", Arial, Helvetica, sans-serif;
    font-size: 1.76775em;
    font-weight: 800;
  }

  blockquote {
    font-size: 1.25em;
    font-family: "Manrope", Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: 1.5em;
    display: block;
    margin: 0;
    position: relative;
    padding: 2em 0 0;

    &:before {
      content: "\201d";
      font-weight: 900;
      display: block;
      font-size: 3.75em;
      line-height: 1;
      position: absolute;
      top: 0;
      left: -0.05em;
      color: $blue-light;
    }

    &:after {
      content: "\201d";
      display: block;
      width: 100%;
      font-weight: 900;
      font-size: 3.75em;
      color: $blue-light;
    }
  }
}

.quote {
  font-size: 1.5em;
}
/* #contact {
} */

.form-control {
  border: none;
  border-bottom: 1px solid #eee;
}

.btn-green {
  background-color: $green;
  color: white;
  font-weight: 600;
  &:hover {
    background-color: $blue-light;
  }
}

.contact-card {
  h4 {
    text-align: center;
    padding: 15px;
    padding-bottom: 0;
  }
  .list-group-item {
    padding: 15px;
    background-color: #f9f9f9;
    border: none;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}

.full-hero {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  .header-title {
    font-size: 3rem;
    font-family: "Manrope", sans-serif;

    u {
      position: relative;
      text-decoration: none;
      z-index: 1;
      display: inline-block;
      &:after {
        background: #0068bc;
        display: block;
        content: "";
        height: max(0.25em, 0.3em);
        left: 0.05em;
        right: 0.05em;
        bottom: 0.05em;
        position: absolute;
        z-index: -1;
        pointer-events: none;
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
}

.splide__slide {
  display: inline-flex; /* used for all slides vertical align center */
  vertical-align: middle; /* used for all slides vertical align center */
}
/* .splide__slide.is-active { */
// transform: scale(1, 1); /* sets the active slide to scaling of 1 (100%) */
/* } */

.timeline {
  display: flex;
  flex-direction: column;
  //width: 85%;
  margin: 5% auto;

  &__event {
    &.flex {
      display: flex;
    }
    background: #fff;
    margin-bottom: 20px;
    position: relative;
    margin: 20px 0;
    border-radius: 8px;
    //box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    // 0 18px 36px -18px rgba(0, 0, 0, 0.3),
    //0 -12px 36px -8px rgba(0, 0, 0, 0.025);

    &__title {
      font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;
      font-weight: 600;
      color: $dark-green;
      letter-spacing: 1.5px;
    }
    &__content {
      padding: 20px;
    }
    &__date {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      white-space: nowrap;
    }
    &__icon {
      //border-radius: 8px 0 0 8px;
      background: $dark-green;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 40%;
      font-size: 2rem;
      color: #fff;
      padding: 20px;

      /* i {
      } */
    }
    &__description {
      flex-basis: 60%;
    }

    &--type2 {
      &:before {
        background: #87bbfe;
        border-color: $violet;
      }
      &:after {
        background: $violet;
      }
      .timeline__event__date {
        color: #fff;
      }

      .timeline__event__icon {
        background: $violet;
        color: #fff;
      }
      .timeline__event__title {
        color: $violet;
      }
      /* .timeline__event__title {
      } */
    }

    &--type3 {
      &:before {
        background: #aff1b6;
        border-color: $green;
      }
      &:after {
        background: $green;
      }
      .timeline__event__date {
        color: #fff;
      }

      .timeline__event__icon {
        background: $green;
        color: #fff;
      }
      .timeline__event__title {
        color: $green;
      }
      /* .timeline__event__title {
      } */
    }

    &--type4 {
      &:before {
        background: #87bbfe;
        border-color: $blue-light;
      }
      &:after {
        background: $blue-light;
      }
      .timeline__event__date {
        color: #fff;
      }

      .timeline__event__icon {
        background: $blue-light;
        color: #fff;
      }
      .timeline__event__title {
        color: $blue-light;
      }
      /* .timeline__event__title {
      } */
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.coloring {
  color: $blue-light;
}

.large {
  width: 50%;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
  }
  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }
}

.splide__pagination {
  opacity: 0;
}

.splide__arrow {
  background-color: #fff !important;
}
.track-container {
  width: 90%;
  justify-content: center;
  margin: auto;
}

.splide__arrow--next {
  right: -2em !important;
}

.splide__arrow--prev {
  left: -2em !important;
}

@media (min-width: 1400px) {
  .img-rotate {
    max-height: 55vh;
    margin-bottom: 2em;
  }

  .header-offre {
    width: 75%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .bottom {
    position: relative;
    top: 187px;
  }

  .responsable {
    font-size: 9.8pt;
  }

  .img-rotate {
    max-height: 55vh;
    margin-bottom: 2em;
  }

  .img-carousel {
    position: relative;
    top: 87px;
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  .track-container {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .img-rotate {
    transform: rotate(-0.25turn);
    margin-left: 265px;
    margin-top: -200px;
    margin-bottom: -200px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .img-rotate {
    transform: rotate(-0.25turn);
    transform-origin: 20% 20%;
    margin-left: 90%;
    margin-bottom: -270%;
  }

  .large {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .img-rotate {
    transform: scale(0.25) rotate(-0.25turn);
    transform-origin: 20% 20%;
    margin-top: -60%;
    margin-bottom: -305%;
  }

  #submit {
    margin: auto;
  }

  .president {
    margin-top: 50px;
  }

  .responsable {
    font-size: 9.3pt;
  }

  .responsable1 {
    font-size: 11pt;
  }

  .offer {
    top: 20px;
  }

  .overlay-offer {
    width: 90vw !important;
    margin-top: 20px;
  }

  .space-offer {
    margin-top: 25px;
  }

  .bg-white {
    margin-top: 0 !important;
    margin-bottom: 25px !important;
  }

  .audit-title {
    width: 85%;
  }

  .address {
    width: 100%;
    padding: 0;
  }

  .footer {
    padding: 1rem !important;
  }

  .dispo {
    padding-top: 45px;
  }

  .navbar-brand {
    width: 150px;
  }

  .header-title {
    font-size: 1.8rem !important;
  }

  .header-description {
    font-size: 1.1rem !important;
  }

  .large {
    width: 80%;
  }

  .up {
    margin-top: -30px;
  }

  .logos .splide__slide {
    height: 100px;
  }

  .span {
    height: 1px;
  }

  .contact-title {
    font-size: 16px;
  }

  .dynamic {
    text-align: center;
  }
}
